import { ArrowForward } from '@mui/icons-material';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { LocalizedLink as Link } from 'gatsby-theme-i18n';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from '../../layout/styles';

const containerStyles: SxProps = {
  background: 'url(/images/intro-bg.jpg) rgba(0,0,0, 0.45)',
  position: 'relative',
  backgroundSize: 'cover',
  minHeight: '500px',
  display: 'flex',
  backgroundPosition: 'center center',
  backgroundBlendMode: 'multiply',
  color: 'white',
  flexDirection: 'column',
  padding: '2rem',
};

const subtitleStyles: SxProps = {
  fontWeight: 400,
  marginTop: '1rem',
  maxWidth: {
    xs: '100%',
    sm: '60%',
    md: '50%',
    lg: '40%',
  },
};

const paperStyles: SxProps = {
  color: 'white',
  padding: '1em',
  backgroundColor: theme.palette.primary.main,
  flex: 1,
  transition: 'background-color .3s ease',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
};

const tractorHeadsLink = `/search?conditions=%7B"vehicle.external.general.category"%3A%7B"condition"%3A"OR"%2C"filters"%3A%5B%7B"data"%3A"Tractor%20Head"%2C"fieldId"%3A"vehicle.external.general.category"%2C"operator"%3A"IS"%7D%5D%7D%7D`;
const trucksLink = `/search?conditions=%7B"vehicle.external.general.category"%3A%7B"condition"%3A"OR"%2C"filters"%3A%5B%7B"data"%3A"Truck"%2C"fieldId"%3A"vehicle.external.general.category"%2C"operator"%3A"IS"%7D%5D%7D%7D`;

export const Categories = () => {
  const { t } = useTranslation('homepage');
  const actions = [
    {
      title: t('common:vehicles.tractor_heads'),
      to: tractorHeadsLink,
    },
    {
      title: t('common:vehicles.trucks'),
      to: trucksLink,
    },
  ];

  return (
    <Paper sx={containerStyles}>
      <Box sx={{ marginBottom: '1.5rem' }}>
        <Typography sx={{ whiteSpace: 'normal', wordBreak: 'break-word' }} variant="h2">
          {t('categories.title')}
        </Typography>
        <Typography sx={subtitleStyles} variant="h6">
          {t('categories.subtitle')}
        </Typography>
      </Box>

      <Grid container spacing={3} sx={{ marginTop: 'auto' }}>
        {actions.map(({ title, to }) => (
          <Grid container item xs={12} sm={6} md={3} key={to}>
            <Link to={to} style={{ display: 'flex', flex: 1 }}>
              <Paper sx={paperStyles} elevation={3}>
                <Typography
                  sx={{
                    fontSize: '2rem',
                    lineHeight: '2.5rem',
                    marginBottom: '2.5rem',
                    whiteSpace: 'normal',
                    wordBreak: 'break-word',
                  }}
                  variant="h6"
                >
                  {title}
                </Typography>
                <Typography
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: 'auto',
                    '& svg': {
                      fontSize: '1.5em',
                      marginLeft: '5px',
                    },
                  }}
                  component="p"
                  variant="body1"
                >
                  {t('common:buttons.show_all')}
                  <ArrowForward />
                </Typography>
              </Paper>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};
