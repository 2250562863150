import { Box, Button, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { LocalizedLink as Link } from 'gatsby-theme-i18n';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Logo from '../../layout/Logo';
import { theme } from '../../layout/styles';

const logoStyles: SxProps = {
  padding: '3vw',
  color: theme.palette.primary.main,
  '& svg': {
    width: '480px',
    height: '320px',
    '& path': {
      fill: 'currentColor',
    },
  },
};

export const Cta = () => {
  const { t } = useTranslation('homepage');
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr',
        alignItems: 'center',
        textAlign: 'center',
        [theme.breakpoints.up('lg')]: {
          gridTemplateColumns: '1.5fr 1fr',
          textAlign: 'start',
        },
      }}
    >
      <Box>
        <Typography sx={{ fontWeight: 600 }} color="primary" variant="h2">
          {t('cta.title')}
        </Typography>
        <Typography
          sx={{ color: theme.palette.grey[600], padding: '1rem 0 1rem', fontWeight: 400 }}
          variant="h6"
        >
          {t('cta.subtitle')}
        </Typography>
        <Box
          sx={{
            padding: '1rem 0',
            display: 'flex',
            flexWrap: 'wrap',
            margin: '-0.5rem',
            '& > a': {
              padding: '0.5rem',
              whiteSpace: 'nowrap',
            },
            justifyContent: 'center',
            [theme.breakpoints.up('lg')]: {
              justifyContent: 'flex-start',
            },
          }}
        >
          <Link to="/search">
            <Button
              size="large"
              sx={{ fontSize: '1.5rem', borderRadius: '1.2em', px: 5 }}
              color="primary"
              variant="contained"
            >
              {t('cta.browse')}
            </Button>
          </Link>
        </Box>
        <Box
          sx={{
            paddingTop: '2rem',
            '& p': {
              color: theme.palette.grey[600],
            },
            [theme.breakpoints.up('lg')]: {
              paddingTop: '5rem',
            },
          }}
        >
          <Typography component="p" variant="body1">
            {t('cta.manufacturers')}
          </Typography>
          <Box
            sx={{
              paddingTop: '1rem',
              '& img': {
                objectFit: 'contain',
                display: 'inline-block',
                height: '50px',
                maxWidth: '100%',
              },
            }}
          >
            <img src="/images/makes.png" height="50" />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'none',

          [theme.breakpoints.up('lg')]: {
            display: 'block',
          },
        }}
      >
        <Logo sx={logoStyles} />
      </Box>
    </Box>
  );
};
