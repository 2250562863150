import { Box, Grid, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from '../../layout/styles';
import { concatSx } from '../../utils/formatters';
type TileProps = {
  quote: string;
  name: string;
  jobTitle: string;
  image: string;
};

const customerContentStyles: SxProps = {
  width: {
    xs: 'calc(80% - 6px)',
    lg: 'calc(60% - 6px)',
  },
};
const imageStyles: SxProps = {
  float: 'left',
  margin: 0,
  '& > img': {
    borderRadius: '10px',
    width: '100%',
  },
  [theme.breakpoints.up('sm')]: {
    float: 'none',
  },
  [theme.breakpoints.only('xs')]: {
    width: '95px',
  },
};

const quoteStyles: SxProps = {
  display: 'block',
  fontSize: '1rem',
  [theme.breakpoints.up('sm')]: {
    padding: '1rem 0',
  },
  [theme.breakpoints.up('md')]: {
    padding: '2rem 0',
    fontSize: '1.85rem',
  },
};

const clientInfoStyles: SxProps = {
  fontSize: {
    xs: '0.8rem',
    sm: '1rem',
  },
  '& > hr': {
    display: 'block',
    margin: '1rem 0',
  },
};
const titleStyles: SxProps = {
  marginBottom: '3rem',
  fontSize: '1.75rem',
  [theme.breakpoints.between('sm', 'lg')]: {
    fontSize: '2rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '3.75rem',
    marginBottom: '4rem',
  },
};

const feedbackStyles: SxProps = {
  marginLeft: 'auto',
  margin: 0,
  [theme.breakpoints.up('sm')]: {
    width: 'calc(71.42857% - 8.57143px)',
  },
};

const FeedbackTile = ({ quote, name, jobTitle, image }: TileProps) => {
  const { t } = useTranslation('homepage');

  return (
    <>
      <Box sx={concatSx(imageStyles, customerContentStyles)}>
        {/* optimum image size is 320x480 */}
        <img width="320" src={image} alt={`${name}'s image`} />
      </Box>
      <Box
        sx={{
          marginLeft: {
            xs: '130px',
            sm: 0,
          },
        }}
      >
        <Typography sx={quoteStyles} component="q" variant="h5" color="primary">
          {quote}
        </Typography>
        <Box sx={concatSx(clientInfoStyles, customerContentStyles)}>
          <hr />
          <Typography sx={{ fontWeight: 800, fontSize: '1em' }} component="strong">
            {name}
          </Typography>
          <Typography sx={{ color: theme.palette.grey[500], fontSize: '1em' }}>
            {jobTitle}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export const CustomerFeedback = () => {
  const { t } = useTranslation('homepage');
  const feedback: TileProps[] = [
    {
      name: 'Marla Robinson',
      quote: 'The freelance talent we work with are more productive than we ever thought possible.',
      jobTitle: 'Web developer, The Truck Company',
      image: 'https://www.upwork.com/static/assets/Brontes/2f4d602/img/cara.4d5446c.jpg',
    },
    {
      name: 'Michael Janice',
      quote:
        'My relationship with Cara & CompuVision keeps on growing. The projects get larger and more technical every year.',
      jobTitle: 'Lead developer, The Truck Company',
      image: 'https://www.upwork.com/static/assets/Brontes/2f4d602/img/sam.10e14e3.jpg',
    },
  ];
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={7}>
        <Typography sx={titleStyles} variant="h2">
          {t('feedback.title')}
        </Typography>
        <Box sx={feedbackStyles}>
          <FeedbackTile {...feedback[0]} />
        </Box>
      </Grid>
      <Grid item xs={12} sm={5}>
        <FeedbackTile {...feedback[1]} />
      </Grid>
    </Grid>
  );
};
