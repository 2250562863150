import { MultiLangValue } from '@bisondesk/commons-sdk/lib/types';
import React from 'react';

export type GetMultiLangValueFn = (values: MultiLangValue | string, inputlang?: string) => string;

export const MultiLang = ({
  values,
  defaultValue,
}: {
  values: string | MultiLangValue;
  defaultValue?: string | JSX.Element;
}): JSX.Element | null => {
  const { getMultiLangValue } = useMultilang();
  return <>{getMultiLangValue(values) ?? defaultValue}</>;
};

const mainLanguage = 'en';

export const useMultilang = () => {
  const getMultiLangValue: GetMultiLangValueFn = (values, inputlang) => {
    if (!values) {
      return '';
    }

    if (typeof values === 'string') {
      return values;
    }

    const lang = inputlang ?? mainLanguage;

    if (values[lang]) {
      return values[lang];
    }

    return values[mainLanguage];
  };

  const multiLangCompare = (a: MultiLangValue, b: MultiLangValue): number => {
    const aValue = getMultiLangValue(a) ?? '';
    const bValue = getMultiLangValue(b) ?? '';
    return aValue.localeCompare(bValue, mainLanguage, { sensitivity: 'base' });
  };

  return {
    getMultiLangValue,
    multiLangCompare,
  };
};
