import { Button, Paper, styled, Typography } from '@mui/material';
import { Box, SxProps } from '@mui/system';
import { LocalizedLink as Link } from 'gatsby-theme-i18n';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from '../../layout/styles';

const classes: { [className: string]: SxProps<any> } = {
  container: {
    background: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  featuredImage: {
    minHeight: '250px',
    flex: 1,
    backgroundPosition: 'center center',
    borderRadius: 'inherit',
    backgroundImage: 'url(/images/driver.jpg)',
    backgroundSize: 'cover',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    boxShadow: '0px 4px 4px 0px rgb(0 0 0 / 25%)',
    [theme.breakpoints.up('md')]: {
      boxShadow: 'none',
      borderTopRightRadius: 0,
      borderBottomLeftRadius: 'inherit',
    },
  },
  content: {
    padding: '2rem',
    flex: 1,
    color: 'white',
    borderRadius: 'inherit',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      borderBottomLeftRadius: 0,
      borderTopRightRadius: 'inherit',
      boxShadow: '-4px 0px 4px 0px rgb(0 0 0 / 25%)',
    },
  },
  title: {
    marginBottom: '1rem',
    wordBreak: 'break-word',
  },
  subtitle: {
    fontWeight: 400,
  },
  top: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '85%',
    },
  },
  bottom: {
    borderTop: '1px solid white',
    marginTop: '3rem',
    paddingTop: '1rem',
    '& > p': {
      marginBottom: '0.5rem',
    },
    '& button': {
      marginTop: '1rem',
    },
  },
};

const CustomButton = styled(Button)({
  background: 'white',
  borderRadius: '1.3rem',
  color: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: '#d5d5d5',
  },
});

const leasingLink =
  '/search?conditions=%7B"vehicle.external.salesConditions.leasing"%3A%7B"condition"%3A"OR"%2C"filters"%3A%5B%7B"data"%3A"true"%2C"fieldId"%3A"vehicle.external.salesConditions.leasing"%2C"operator"%3A"IS"%7D%5D%7D%7D';

export const Leasing = () => {
  const { t } = useTranslation('homepage');
  const remarks: string[] = t('leasing.remarks', { returnObjects: true, defaultValue: [] });

  return (
    <Paper sx={classes.container}>
      <Box sx={classes.featuredImage} />
      <Box sx={classes.content}>
        <Box sx={classes.top}>
          <Typography sx={classes.title} variant="h2">
            {t('leasing.title')}
          </Typography>
          <Typography sx={classes.subtitle} variant="h6">
            {t('leasing.subtitle')}
          </Typography>
        </Box>
        <Box sx={classes.bottom}>
          {remarks.map((text, i) => (
            <Typography key={i}>{text}</Typography>
          ))}
          <Link to={leasingLink}>
            <CustomButton size="large">{t('leasing.link')}</CustomButton>
          </Link>
        </Box>
      </Box>
    </Paper>
  );
};
