import { PublicVehicle } from '@bisondesk/core-sdk/src/types/vehicles';

export const getVehicleDescription = (vehicle: PublicVehicle, lang: string) => {
  const kmFormatter = Intl.NumberFormat(lang, { style: 'unit', unit: 'kilometer' });
  return [
    vehicle.external.history?.advertisingYear,
    vehicle.external.condition?.odometer?.km != null
      ? kmFormatter.format(vehicle.external.condition.odometer.km)
      : undefined,
    vehicle.external.powertrain?.emissions?.class,
    vehicle.external.powertrain?.axles?.configuration,
    vehicle.external.powertrain?.axles?.suspension,
  ]
    .filter((s) => !!s)
    .join(' • ');
};
