import { PublicSearchVehicle } from '@bisondesk/core-sdk/lib/types/vehicles';
import { Box, Button, Grid, Typography } from '@mui/material';
import { LocalizedLink as Link } from 'gatsby-theme-i18n';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from '../../layout/styles';
import { VehicleGalleryItem } from '../vehicles/VehicleGalleryItem';

export const RecentVehicles = ({ vehicles }: { vehicles: PublicSearchVehicle[] }) => {
  const { t } = useTranslation('homepage');
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '2rem',
        }}
      >
        <Typography
          sx={{
            fontSize: {
              xs: '1.75rem',
              md: '2.5rem',
            },
          }}
          variant="h2"
        >
          {t('recentVehicles.title')}
        </Typography>
        <Link to="/search">
          <Button size="large" variant="contained" color="primary" sx={{ borderRadius: '1.3rem' }}>
            See more
          </Button>
        </Link>
      </Box>
      <Box style={{ overflowX: 'auto' }}>
        <Grid
          container
          spacing={2}
          sx={{
            [theme.breakpoints.down('sm')]: {
              flexWrap: 'nowrap',
              '& > div': {
                flex: 'none',
                width: '85%',
                maxWidth: 'none',
              },
            },
          }}
        >
          {vehicles.map((record: PublicSearchVehicle) => (
            <Grid item sm={6} md={4} lg={3} key={record.vehicle.id} style={{ display: 'flex' }}>
              <VehicleGalleryItem record={record} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};
