import { PublicSearchVehicle } from '@bisondesk/core-sdk/lib/types/vehicles';
import { Box, Card, CardContent, CardHeader } from '@mui/material';
import { SxProps } from '@mui/system';
import { LocalizedLink as Link } from 'gatsby-theme-i18n';
import React from 'react';
import { colors } from '../../layout/styles';
import { MultiLang } from '../MultiLang';
import { getVehicleDescription } from './utils';

type Props = {
  record: PublicSearchVehicle;
  RecordMoreActions?: React.ReactNode;
};

const cardHeaderStyles: SxProps = {
  padding: '8px 4px 4px 8px',
  '& .MuiCardHeader-action': {
    alignSelf: 'auto',
    marginTop: 0,
    marginRight: 0,
  },
  '& .MuiCardHeader-content': {
    color: colors.primary.A600,
    fontSize: '16px',
    fontWeight: 500,
  },
};

export const VehicleGalleryItem = ({ record }: Props) => {
  const originalImgUrl = record.vehicle.external.mainPicture?.url;
  const actualImgUrl = originalImgUrl
    ? `${originalImgUrl}?w=250&h=200`
    : '/images/placeholder-350-250.png';

  const moneyFormatter = Intl.NumberFormat('es-BO', {
    style: 'currency',
    currency: record.vehicle.external.salesConditions?.currency,
    maximumFractionDigits: 0,
    // Fix for samsung browser
    minimumFractionDigits: 0,
  });
  const salesPrice = record.vehicle?.external.salesConditions.price;

  return (
    <Card
      sx={{
        outline: 'none',
        margin: 0,
        display: 'grid',
        flex: 1,
        gridTemplateRows: 'auto auto 1fr',
        borderBottom: `4px solid ${colors.primary.A500}`,
      }}
    >
      <CardContent
        sx={{
          padding: 0,
          '& a': {
            display: 'block',
            minHeight: '200px',
            background: '#ccc',
          },
          '& img': {
            display: 'block',
          },
        }}
      >
        <Link to={`/vehicles/${record.vehicle.external.identification.slug}`}>
          <img
            alt=""
            src={actualImgUrl}
            style={{ width: '100%', height: 'auto', minHeight: '200px' }}
          />
        </Link>
      </CardContent>
      <CardHeader
        sx={cardHeaderStyles}
        disableTypography
        subheaderTypographyProps={{ variant: 'overline' }}
        title={
          <Link to={`/vehicles/${record.vehicle.external.identification.slug}`}>
            <MultiLang values={record.vehicle.external.description?.titles} />
          </Link>
        }
      />
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          '& > * + *': {
            marginTop: '4px',
          },
          '&:last-child': {
            paddingBottom: '8px',
          },
          padding: '4px 4px 8px 8px',
        }}
      >
        <div>{getVehicleDescription(record.vehicle, 'es-BO')}</div>
        <div>
          <Box sx={{ fontSize: '20px', fontWeight: 600, color: colors.primary.A900 }}>
            {salesPrice ? moneyFormatter.format(Number(salesPrice)) : 'N/A'}
          </Box>
        </div>
      </CardContent>
    </Card>
  );
};
