import { PublicSearchVehicle } from '@bisondesk/core-sdk/lib/types/vehicles';
import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import { graphql } from 'gatsby';
import React, { useMemo } from 'react';
import { Categories } from '../components/homepage/Categories';
import { Cta } from '../components/homepage/Cta';
import { CustomerFeedback } from '../components/homepage/CustomerFeedback';
import { Leasing } from '../components/homepage/Leasing';
import { RecentVehicles } from '../components/homepage/RecentVehicles';
import Layout from '../layout/Layout';

type Data = {
  allRecentVehicle: {
    nodes: Array<{
      data: string;
    }>;
  };
};

const containerStyles: SxProps = {
  '& > section': {
    padding: {
      xs: '1rem 0',
      sm: '1rem',
      md: '3rem 2em',
    },
  },
};

export const query = graphql`
  query {
    allRecentVehicle {
      nodes {
        data
      }
    }
  }
`;

export default ({ data, location }: { location: Location; data: Data }) => {
  const recentVehicles: PublicSearchVehicle[] = useMemo(
    () => data.allRecentVehicle.nodes.map((node) => JSON.parse(node.data)),
    [data.allRecentVehicle.nodes]
  );

  return (
    <Layout location={location}>
      <Box sx={containerStyles}>
        <section>
          <Cta />
        </section>
        <section>
          <Categories />
        </section>
        <section>
          <RecentVehicles vehicles={recentVehicles} />
        </section>
        <section style={{ overflow: 'hidden' }}>
          <CustomerFeedback />
        </section>
        <section>
          <Leasing />
        </section>
      </Box>
    </Layout>
  );
};
